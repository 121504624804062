<script>
import { mapActions, mapGetters } from 'vuex';

export default {
    name: 'PaymentSystems',

    data: () => ({
        loading: false,
    }),

    methods: {
        ...mapActions('paymentSystems', [
            'getAllPaymentSystems',
            'changePaymentStatus',
        ]),

        getColor(active) {
            if (active) return 'green';
            else return 'orange';
        },

        async changeActivePayment(id, is_active) {
            await this.changePaymentStatus({
                payment_system_id: id,
                is_active,
            })
                .then(() => {
                    this.$alertify.success('Success change status payment');
                    this.getAllPaymentSystems();
                })
                .catch(({ response: { data } }) => {
                    this.$alertify.error(data.message);
                });
        },
    },

    computed: {
        ...mapGetters('paymentSystems', {
            allPaymentSystems: 'getPaymentSystems',
        }),

        headers() {
            return [
                {
                    text: 'Name',
                    value: 'name',
                    width: 350,
                    sortable: false,
                },
                {
                    text: 'Change',
                    value: 'action',
                    align: 'center',
                    sortable: false,
                },
            ];
        },
    },

    async created() {
        this.loading = true;
        await this.getAllPaymentSystems();
        this.loading = false;
    },
};
</script>

<template>
    <div style="display: flex; justify-content: center;">
        <v-data-table
            :loading="loading"
            loading-text="Loading... Please wait"
            hide-default-footer
            :headers="headers"
            class="elevation-1"
            :items="loading ? [] : allPaymentSystems"
        >
            <template v-slot:[`item.name`]="{ item }">
                <span style="margin-right: 10px">
                    {{ item.name }}
                </span>
            </template>

            <template v-slot:[`item.action`]="{ item }">
                <v-switch
                    style="margin:0"
                    v-model="item.is_active"
                    @change="changeActivePayment(item.id, +item.is_active)"
                    color="success"
                    hide-details
                >
                    <template v-slot:label>
                        <strong v-if="item.is_active === 0">
                            Not active
                        </strong>
                        <strong class="primary--text" v-else>
                            Active
                        </strong>
                    </template>
                </v-switch>
            </template>
        </v-data-table>
    </div>
</template>
